
import { createSignal } from "solid-js"; 
import { Button, Typography, Link , Divider, Stack, Dialog, DialogActions, DialogTitle, DialogContent, Box } from "@suid/material";
import { FieldEmail } from "../SmallComponents/SmallFieldEmail";
import { getURL } from '../../../utils/fetchAPI'
import { getText } from '../SmallComponents/Translate'
import { showError } from '../SmallComponents/SmallShowError'
import toast  from 'solid-toast';

import { MailIcon } from "../icons" 

const apiUrl = import.meta.env.VITE_APP_API_URL;

const ForgotPassword = () => {
    const [open, setOpen] = createSignal(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [userEmail, setUserEmail] = createSignal('');

    //const { userStore, setUserStore } = useUserContext();
    //const { userStore } = useUserContext() as { userStore: UserStore };
    //const { setUserStore } = useUserContext() as { setUserStore: SetStoreFunction<UserStore> };

    const handleChangeEmail = (email: string) => {
        setUserEmail(email)
    }

    const handleSendResetEmail = () => {

        type LoginUser = {
            status: string,
            redirect: string,
            userId?: string,
            userFirstName?: string,
            userLastName?: string,
            userCompanyId?: string;
            userCompanyName?: string,
            userAvatar?: string,
            userLanguage?: string;
            userCountry?: string;
        }
        const txtSuccess = getText("forgotpassword", "success")
        const txtError = getText("default", "error", [{ name: 'during', value: '' }])
        //passwordreset 
        getURL(`${apiUrl}/security/forgotpassword`, 'POST', { email: userEmail() })
            .then((fetchedData: LoginUser) => {

                setOpen(false);
                //   navigate("/")  
                toast.success(txtSuccess() + userEmail(), { duration: 5000, });

            }).catch((error) => {
                showError(txtError() as string, `${error}`);
            })
    };
//<Link href="#" onClick={handleOpen}> Forgot password?</Link>
// <Button variant="outlined" onClick={handleOpen}>Forgot password?</Button>

    const txtHeader = getText("forgotpassword", "header")
    const txtAction = getText("forgotpassword", "actiontext")
    const txtEmail = getText("contact", "email")
    const txtClose = getText("default", "close")
    const txtSubmit = getText("forgotpassword", "submit")

    return (
        <>
             <Link href="#" onClick={handleOpen}>{txtHeader()}</Link>
            {open() && (
                <Dialog open={true} >
                    <Box
                        component="form"
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSendResetEmail();
                        }}
                    >
                        <DialogTitle>
                            {" "}
                            <Typography variant="h4">{txtHeader()}</Typography>
                        </DialogTitle>
                        <DialogContent>

                            <Typography variant="subtitle1">{txtAction()}</Typography>

                            <Stack margin={2} spacing={2}>
                                <FieldEmail
                                    id="forgotPassworduserEmail" label={txtEmail() as string} textValue=""
                                    valueOnChange={value => handleChangeEmail(value)}
                                />
                            </Stack>
                            <Stack margin={2} spacing={2}>
                                <Divider />
                            </Stack>

                        </DialogContent>
                        <DialogActions>
                            <Button variant="outlined" color="info" onClick={handleClose}>{txtClose()}</Button>
                            <Button id="sendPasswordResetEmail" variant="contained" startIcon={<MailIcon />} type="submit">{txtSubmit()}</Button>
                        </DialogActions>
                    </Box>
                </Dialog>
            )}
        </>
    );
}
export {ForgotPassword}