import { createSignal, Show, onMount } from 'solid-js';
import { useNavigate } from "@solidjs/router";
import { BasicAppBar } from '../components/AppMenuBar/AppBarMenu';
import { ShowHeading } from '../components/SmallComponents/SmallHeader'

import {
  CircularProgress, Button, Checkbox, FormGroup, Stack, Grid, Card, CardMedia,
  Divider, Dialog, DialogActions, DialogContent, Box, FormControlLabel,
} from "@suid/material";
import { FieldEmail } from "../components/SmallComponents/SmallFieldEmail";
import { FieldPassword } from "../components/SmallComponents/SmallFieldPassword";
import { ForgotPassword } from "../components/AppMenuBar/ForgotPassword";
import { getURL } from '../../utils/fetchAPI'
import toast from 'solid-toast';
import { getText } from '../components/SmallComponents/Translate'
import { showError } from '../components/SmallComponents/SmallShowError'
import { handleLogin } from '../../utils/isloggedin'
 
import { KeyIcon } from "../components/icons"

import { getUserStore, setUserStore } from "../context/userContext";

const apiUrl = import.meta.env.VITE_APP_API_URL;

const ShowLoginPage = () => {
  const navigate = useNavigate();
  const rememberMeCheck = localStorage.getItem('RememberMe');
  const [open, setOpen] = createSignal(true);
  const handleClose = () => { 
    setOpen(false);
    navigate('/')
  }
  const [disabled, setDisabled] = createSignal(false);
  const [userName, setUserName] = createSignal(rememberMeCheck ? rememberMeCheck : '');
  const [userPassword, setUserPassword] = createSignal('');

  const [rememberMe, setRememberMe] = createSignal(rememberMeCheck ? true : false);

  const handleRememberMe = (checked: boolean) => {
    setRememberMe(checked);

    if (checked) {
      localStorage.setItem('RememberMe', userName());
    } else {
      localStorage.setItem('RememberMe', "");
    }
  }



  const handleSignIn = () => {

    type LoginUser = {
      status: string,
      redirect: string,
      userId?: string,
      userFirstName?: string,
      userLastName?: string,
      userCompanyId?: string;
      userCompanyName?: string,
      userAvatar?: string,
      userLanguage?: string;
      userCountry?: string;
    }

    const txtWelcome = getText("login", "welcome")
    const txtSuccess = getText("login", "success")
    const txtErrorExt = getText("login", "errorextension")
    const txtError = getText("default", "error", [{ name: 'during', value: txtErrorExt() as string }])

    //    getURL(`${apiUrl}/api/security/login`, 'POST', { email: userName(), password: userPassword() })
    getURL(`${apiUrl}/security/login`, 'POST', { email: userName(), password: userPassword(), type: "password" })
      .then((fetchedData: LoginUser) => {


        if (fetchedData.status === '200') {


          if (rememberMe()) {
            localStorage.setItem('RememberMe', userName());
          } else {
            localStorage.setItem('RememberMe', "");
          }

          handleLogin()

          setUserStore({
            userId: fetchedData.userId as string,
            userFirstName: fetchedData.userFirstName as string,
            userLastName: fetchedData.userLastName as string,
            userCompanyId: fetchedData.userCompanyId as string,
            userCompanyName: fetchedData.userCompanyName as string,
            userAvatar: fetchedData.userAvatar as string,
            userLanguage: fetchedData.userLanguage as string,
            userCountry: fetchedData.userCountry as string
          });

          const tmpUserStore = getUserStore()
          toast.success(txtWelcome() + tmpUserStore.userFirstName + " " + tmpUserStore.userLastName + txtSuccess(), { duration: 5000, });
          setDisabled(!disabled());

          navigate("/home");
        } else {

          showError(txtError() as string, `${fetchedData.status}`);
        }


      }).catch((error) => {
        showError(txtError() as string, error.message ? `${error.message}` : `${error}`);
      })
  };



  const handleSSOSignIn = () => {

    type LoginUser = {
      status: string,
      redirect: string,
      userId?: string,
      userFirstName?: string,
      userLastName?: string,
      userCompanyId?: string;
      userCompanyName?: string,
      userAvatar?: string,
      userLanguage?: string;
      userCountry?: string;
    }

    const txtWelcome = getText("login", "welcome")
    const txtSuccess = getText("login", "success")
    const txtErrorExt = getText("login", "errorextension")
    const txtError = getText("default", "error", [{ name: 'during', value: txtErrorExt() as string }])

    //    getURL(`${apiUrl}/api/security/login`, 'POST', { email: userName(), password: userPassword() })
    getURL(`${apiUrl}/security/login`, 'POST', { email: userName(), password: userPassword(), type: "SSO" })
      .then((fetchedData: LoginUser) => {


        if (fetchedData.status === '200') {


          if (rememberMe()) {
            localStorage.setItem('RememberMe', userName());
          } else {
            localStorage.setItem('RememberMe', "");
          }

          handleLogin()

          setUserStore({
            userId: fetchedData.userId as string,
            userFirstName: fetchedData.userFirstName as string,
            userLastName: fetchedData.userLastName as string,
            userCompanyId: fetchedData.userCompanyId as string,
            userCompanyName: fetchedData.userCompanyName as string,
            userAvatar: fetchedData.userAvatar as string,
            userLanguage: fetchedData.userLanguage as string,
            userCountry: fetchedData.userCountry as string
          });

          const tmpUserStore = getUserStore()
          toast.success(txtWelcome() + tmpUserStore.userFirstName + " " + tmpUserStore.userLastName + txtSuccess(), { duration: 5000, });
          setDisabled(!disabled());

          navigate("/home");
        } else {

          showError(txtError() as string, `${fetchedData.status}`);
        }


      }).catch((error) => {
        showError(txtError() as string, error.message ? `${error.message}` : `${error}`);
      })
  };

  const txtHeader = getText("menu", "login")
  const txtEmail = getText("contact", "email")
  const txtPassword = getText("contact", "password")
  const txtRemember = getText("login", "rememberme")
  const txtSubmit = getText("login", "submit")

  const startSignIn = () => {
    return (
      <CircularProgress color="secondary" />
    )
  }

 
 

  return (
    <>

      <BasicAppBar />

      {open() && (

      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}>
        <Box sx={{ margin: 5, maxWidth: 800, bgcolor: 'background.default'[200] }}>

          {/* ****************** */}
          {/* Onboard main part */}

          <ShowHeading pxValue={2} > {txtHeader()} </ShowHeading>


          <Divider />

          <Card sx={{ margin: 2, display: 'flex' }} >




            <Box
              component="form"
              onSubmit={(e) => {
                e.preventDefault();

              }}
            >
<Dialog open={true} onBackdropClick={handleClose}>
              <DialogContent>

                <Grid container spacing={2}>

                  <Grid item xs={2} md={4} container alignItems="center" justifyContent="center">

                    <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 2 }}>
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                        <CardMedia
                          component="img"
                          sx={{ maxWidth: '100%', maxHeight: '100%' }}
                          image="/assets/imgs/signup/08.png"
                          alt="Live from space"
                        />
                      </Box>
                    </Card>
                  </Grid>

                  <Grid item xs={10} md={8}  >

                    <Stack margin={1} spacing={2} alignItems="center"
                      justifyContent="center">
                      <FieldEmail
                        id="loginUserEmail" label={txtEmail()} textValue={userName()}
                        valueOnChange={value => setUserName(value)}
                      />

                      <FieldPassword
                        id="loginUserPassword" label={txtPassword()} textValue={txtPassword()} dontShowIcon={true}
                        valueOnChange={value => setUserPassword(value)}
                      />


                    </Stack>

                    <Divider />

                    <Stack margin={1} alignItems="center"
                      justifyContent="center">
                      <FormGroup>
                        <Show
                          fallback={
                            <FormControlLabel control={<Checkbox checked={rememberMe()}
                              onChange={(event, checked) => {
                                handleRememberMe(checked);
                              }} />} label={txtRemember()} />
                          }
                          when={rememberMe()}
                        >
                          <FormControlLabel control={<Checkbox defaultChecked checked={rememberMe()}
                            onChange={(event, checked) => {
                              handleRememberMe(checked);
                            }} />} label={txtRemember()} />
                        </Show>
                      </FormGroup>
                    </Stack>

                  </Grid>
                </Grid>
                <Divider />
              </DialogContent>
              <DialogActions>

                <Stack direction="row" margin={1} spacing={2} alignItems="center">

                  <ForgotPassword />

                  <Button variant="contained" startIcon={<KeyIcon />} onClick={handleSignIn} type="submit" >{txtSubmit()}</Button>
                  <Button variant="contained" startIcon={<KeyIcon />} onClick={handleSSOSignIn} type="submit" >SSO</Button>
                </Stack>

              </DialogActions>
              </Dialog>
            </Box>
          </Card>
        </Box>
      </Box>
      )}
    </>
  );
}

export { ShowLoginPage }